import * as React from "react";

// plugin imports
import axios from "axios";

// utils imports

// svg imports

export const RouteContext = React.createContext({
	location: undefined,
});

const defaultValues = {
	fetchCarta: () => {},
	menuData: undefined,
	menuError: false,
};

export const MenuDataContext = React.createContext(defaultValues);

export const MenuDataProvider = ({ children }) => {
	const [menuData, setMenuData] = React.useState(undefined);
	const [menuError, setMenuError] = React.useState(false);

	const fetchCarta = async () => {
		// const APIKey = restaurant.frontmatter.cartaAPIKey;
		const APIKey = "AIzaSyDg-kWvTIlW9_WQKPzuS0eUWKIKNly6r1w";
		const spreadsheetId = "1RR3WaNNZUb2ESMAioEVihvCmYApvCUrwgWTNZStkKpc";
		const sheetName = "Carta";

		if (!menuData) {
			axios
				.get(
					`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?key=${APIKey}`
				)
				.then((response) => {
					const cartaArray = [];

					const platos = response.data.values;

					for (var i = 1; i < platos.length; i++) {
						// we start at i = 1 cause i = 0 are headers

						var row = {}; // Create a new row object for each row
						for (var j = 0; j < 100; j++) {
							if (platos[0][j] === undefined) {
								break;
							}
							row[platos[0][j]] = platos[i][j];
						}
						cartaArray.push(row); // Push the row object to the output array
					}
					setMenuData(cartaArray);
				})
				.catch((error) => {
					console.error("Error:", error);
					setMenuError(error);
				});
		}
	};

	return (
		<MenuDataContext.Provider
			value={{
				...defaultValues,
				fetchCarta,
				menuData,
				menuError,
			}}
		>
			{children}
		</MenuDataContext.Provider>
	);
};
