import * as React from "react";

// plugin imports

// utils imports

// svg imports
import AlergenosGluten from "../svg/icons/allergens/alergenoGluten.svg";
import AlergenoCrustaceos from "../svg/icons/allergens/alergenoCrustaceos.svg";
import AlergenoHuevo from "../svg/icons/allergens/alergenoHuevos.svg";
import AlergenoPescado from "../svg/icons/allergens/alergenoPescado.svg";
import AlergenoCacahuetes from "../svg/icons/allergens/alergenoFrutosSecos.svg";
import AlergenoSoja from "../svg/icons/allergens/alergenoSoja.svg";
import AlergenoLacteos from "../svg/icons/allergens/alergenoLacteos.svg";
import AlergenoFrutosCascara from "../svg/icons/allergens/alergenoFrutosCascara.svg";
import AlergenoApio from "../svg/icons/allergens/alergenoApio.svg";
import AlergenoMostaza from "../svg/icons/allergens/alergenoMostaza.svg";
import AlergenoSesamo from "../svg/icons/allergens/alergenoSesamo.svg";
import AlergenoSulfitos from "../svg/icons/allergens/alergenoSulfitos.svg";
import AlergenoMoluscos from "../svg/icons/allergens/alergenoMoluscos.svg";
import AlergenoAltramuces from "../svg/icons/allergens/alergenoAltramuces.svg";
import AlergenoVegano from "../svg/icons/allergens/alergenoVegano.svg";
import AlergenoVegetariano from "../svg/icons/allergens/alergenoVegetariano.svg";
import AlProLogo from "../svg/icons/allergens/alproLogo.svg";

const defaultValues = {
	showAllergens: false,
	allergensList: [],
};

export const AllergensContext = React.createContext(defaultValues);

export const AllergensProvider = ({ children }) => {
	const [showAllergens, setShowAllergens] = React.useState(false);

	const allergensList = [
		"Gluten",
		"Crustáceos",
		"Huevo",
		"Pescado",
		"Cacahuetes",
		"Soja",
		"Lácteos",
		"Frutos de cáscara",
		"Apio",
		"Mostaza",
		"Granos de sésamo",
		"Dióxido de azufre y sulfitos",
		"Moluscos",
		"Altramuces",
	];

	const translations = {
		Gluten: {
			en: "Gluten",
		},
		Crustáceos: {
			en: "Crustaceans",
		},
		Huevo: {
			en: "Egg",
		},
		Pescado: {
			en: "Fish",
		},
		Cacahuetes: {
			en: "Peanuts",
		},
		Soja: {
			en: "Soy",
		},
		Lácteos: {
			en: "Dairy",
		},
		"Frutos de cáscara": {
			en: "Nuts",
		},
		Apio: {
			en: "Celery",
		},
		Mostaza: {
			en: "Mustard",
		},
		"Granos de sésamo": {
			en: "Sesame seeds",
		},
		"Dióxido de azufre y sulfitos": {
			en: "Sulfur dioxide and sulfites",
		},
		Moluscos: {
			en: "Moluscs",
		},
		Altramuces: {
			en: "Lupins",
		},
		Vegano: {
			en: "Vegan",
		},
		Vegetariano: {
			en: "Vegetarian",
		},
	};

	const dietaList = ["Vegano", "Vegetariano", "AlPro"];

	const allergensIcons = {
		Gluten: <AlergenosGluten />,
		Crustáceos: <AlergenoCrustaceos />,
		Huevo: <AlergenoHuevo />,
		Pescado: <AlergenoPescado />,
		Cacahuetes: <AlergenoCacahuetes />,
		Soja: <AlergenoSoja />,
		Lácteos: <AlergenoLacteos />,
		"Frutos de cáscara": <AlergenoFrutosCascara />,
		Apio: <AlergenoApio />,
		Mostaza: <AlergenoMostaza />,
		"Granos de sésamo": <AlergenoSesamo />,
		"Dióxido de azufre y sulfitos": <AlergenoSulfitos />,
		Moluscos: <AlergenoMoluscos />,
		Altramuces: <AlergenoAltramuces />,
		Vegano: <AlergenoVegano />,
		Vegetariano: <AlergenoVegetariano />,
		AlPro: <AlProLogo />,
	};

	const renderAllergensIcons = (
		allergensList,
		names = false,
		language = "es"
	) => {
		if (allergensList) {
			const allergensArray = allergensList
				? allergensList.split(", ")
				: undefined;

			return (
				<span className="allergens">
					{allergensArray?.length > 0 &&
						allergensArray
							.sort(
								(a, b) => allergensList.indexOf(a) - allergensList.indexOf(b)
							)
							.map((allergen, i) => {
								if (Object.keys(allergensIcons).includes(allergen)) {
									return (
										<span
											className="uppercase allergen"
											key={"alergeno" + i}
											id={allergen.replace(/\s/g, "")}
										>
											<span className="allergenIcon">
												{allergensIcons[allergen]}
											</span>
											{names &&
												` ${translations[allergen][language] || allergen}`}
										</span>
									);
								} else {
									return (
										<span className="small allergenText" key={"alergeno" + i}>
											{allergen}
										</span>
									);
								}
							})}
				</span>
			);
		}
	};

	return (
		<AllergensContext.Provider
			value={{
				...defaultValues,
				showAllergens,
				setShowAllergens,
				allergensList,
				dietaList,
				renderAllergensIcons,
			}}
		>
			{children}
		</AllergensContext.Provider>
	);
};
