module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"G-YE89V4XPXQ","anonymize":true},"facebookPixel":{"pixelId":"000000000","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Veneno concept","short_name":"Veneno","start_url":"/","lang":"es","background_color":"#212020","theme_color":"#fff","display":"minimal-ui","icon":"src/images/favicon.png","localize":[{"start_url":"/en/","lang":"en","name":"Veneno","short_name":"Veneno","description":"people. art. culture ❤️‍🔥"},{"start_url":"/es/","lang":"es","name":"Veneno","short_name":"Veneno","description":"people. art. culture ❤️‍🔥"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef755f61607f0f6b256613da0520856d"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1800,"linkImagesToOriginal":false,"backgroundColor":"none","disableBgImageOnAlpha":true,"withWebp":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../node_modules/@herob191/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es","en"],"defaultLanguage":"es","siteUrl":"https://www.venenoconcept.es/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"react":{"transSupportBasicHtmlNodes":false}},"pages":[{"matchPath":"/dev-404-page","languages":["es"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
